<script>
  /**
   * @type {string | undefined}
   */
  let pageData

  // @ts-ignore
  if (typeof window !== "undefined" && import.meta.env.DEV) {
    pageData = document.getElementById("app")?.dataset?.page
  }
</script>

<div class="flex flex-col gap-4">
  <h1 class="text-2xl font-bold text-center text-gray-900 dark:text-white">
    404
  </h1>
  <p class="text-center text-gray-500 dark:text-stone-400">
    {"Page not found"}
  </p>
  {#if !!pageData}
    <span class="text-center text-yellow-600">
      {"[DEBUG] Page data: "}
      {pageData}
    </span>
  {/if}
</div>
