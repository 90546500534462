<script lang="ts">
  import axios from "axios"
  import { Modal } from "flowbite-svelte"
  import { useI18n } from "$lib/i18n"

  import OmiseSVG from "$/assets/omise/th"

  interface Props {
    showModal: boolean
  }

  interface BankInfo {
    code: string
    color: string
    official_name: string
    nice_name: string
    swift_code: string
  }

  interface BanksJSON {
    th: {
      [key: string]: BankInfo
    }
  }

  interface BanksData {
    abbr: string
    color: string
    niceName: string
  }

  let { showModal = $bindable() }: Props = $props()
  const I18n = useI18n()
  let banksJSON: BanksJSON = { th: {} }
  let banksData: BanksData[] = $state([])

  async function fetchOmiseBanksData() {
    const cacheKey = "OMISE:THAI_BANKS_DATA"
    const cacheExpiryKey = "OMISE:THAI_BANKS_DATA_EXPIRY"
    const cacheExpiry = 24 * 60 * 60 * 1000 // 24 hours

    const cachedData = localStorage.getItem(cacheKey)
    const cachedExpiry = localStorage.getItem(cacheExpiryKey)

    if (cachedData && cachedExpiry && Date.now() < parseInt(cachedExpiry)) {
      banksJSON = JSON.parse(cachedData)
    } else {
      const response = await axios.get(
        "https://raw.githubusercontent.com/omise/banks-logo/master/banks.json",
      )
      banksJSON = response.data
      localStorage.setItem(cacheKey, JSON.stringify(banksJSON))
      localStorage.setItem(
        cacheExpiryKey,
        (Date.now() + cacheExpiry).toString(),
      )
    }
  }

  // 1. reject keys under 'th' key by this list
  // 2. Map object into array of { abbr, color, nice_name }
  async function transposeBanksData() {
    const UNSUPPORTED_BANKS = [
      "rbs",
      "jpm",
      "mufg",
      "tmb",
      "smbc",
      "mega",
      "boa",
      "cacib",
      "db",
      "mb",
      "tbank",
      "bnp",
    ]

    banksData = Object.keys(banksJSON.th)
      .filter(key => !UNSUPPORTED_BANKS.includes(key))
      .map(key => {
        const bank = banksJSON.th[key]
        return {
          abbr: key,
          color: bank.color,
          niceName: bank.nice_name,
        }
      })
  }

  // Don't use $effect with async create async function and call it instead
  // Ref: https://www.youtube.com/watch?v=XsYyruvbNVc
  $effect(() => {
    fetchOmiseBanksData()
    transposeBanksData()
  })
</script>

<Modal
  title={I18n.t("views.shared.supported_banks.title")}
  bind:open={showModal}
  size="xl"
  autoclose
  outsideclose
>
  <div class="flex flex-col gap-8 px-4 py-8 md:px-5 md:py-10">
    <div class="text-center">
      {I18n.t("views.shared.supported_banks.description")}
    </div>

    <div class="grid grid-cols-2 sm:grid-cols-6 gap-2 gap-y-8">
      {#each banksData as bank}
        <div class="flex flex-col items-center gap-2">
          <span class="flex p-4 rounded-lg" style="background-color: {bank.color};">
            <img src={OmiseSVG[bank.abbr]} alt={bank.niceName} class="w-12 h-12" />
          </span>

          <span class="text-sm text-center text-stone-900 dark:text-white">
            {bank.niceName}
          </span>
        </div>
      {/each}
    </div>
  </div>

  <svelte:fragment slot="footer">
    <div class="flex justify-center items-center w-full">
      <button type="button" class="btn btn--brand btn--lg w-full sm:w-fit">
        {I18n.t("views.sellers.sell_wristband.modal.wristband_image_guideline.action")}

        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M7 10v12"/><path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z"/></svg>
      </button>
    </div>
  </svelte:fragment>
</Modal>
