import { createInertiaApp } from "@inertiajs/svelte"
import { mount, unmount } from "svelte"

let app

function loadInertiaApp() {
  // Prevent inertia app to run on non-inertia pages
  if (
    typeof window === "undefined" ||
    document.getElementById("app") === null
  ) {
    return
  }

  createInertiaApp({
    // Set default page title
    // see https://inertia-rails.netlify.app/guide/title-and-meta
    //
    // title: title => title ? `${title} - App` : 'App',

    // Disable progress bar
    //
    // see https://inertia-rails.netlify.app/guide/progress-indicators
    // progress: false,

    resolve: name => {
      const pages = import.meta.glob("../pages/**/*.svelte", { eager: true })
      const page = pages[`../pages/${name}.svelte`]

      if (!page) {
        if (import.meta.env.DEV) {
          console.warn(`Page not found: ${name}.svelte`)
        }

        return pages[`../pages/404.svelte`]
      }

      return page

      // To use a default layout, import the Layout component
      // and use the following lines.
      // see https://inertia-rails.netlify.app/guide/pages#default-layouts
      //
      // const page = pages[`../pages/${name}.svelte`]
      // return { default: page.default, layout: page.layout || Layout }
    },

    setup({ el, App, props }) {
      if (app) unmount(app)

      app = mount(App, { target: el, props })
    },
  })
}

if (Turbo?.session?.enabled) {
  document.addEventListener("turbo:load", loadInertiaApp)
} else {
  loadInertiaApp()
}
