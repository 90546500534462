import { setContext, getContext } from "svelte"
import { I18n as I18nJS } from "i18n-js"
import locales from "$/locales.json"

const I18N_CONTEXT_KEY = Symbol("i18n")

export function initI18n(language: string) {
  const I18n = new I18nJS(locales)

  I18n.locale = language

  setContext(I18N_CONTEXT_KEY, I18n)
}

export function useI18n(): I18nJS {
  const context = getContext<I18nJS>(I18N_CONTEXT_KEY)

  if (context) return context

  initI18n(document.documentElement.lang)

  return getContext<I18nJS>(I18N_CONTEXT_KEY)
}
