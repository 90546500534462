<script lang="ts">
  import {
    Tabs,
    TabItem,
    Radio,
    Datepicker,
    Select,
    Helper,
  } from "flowbite-svelte"
  import { onMount } from "svelte"
  import { merge } from "ts-deepmerge"

  interface Props {
    accountRequirements: Array<Requirement>
    onRefreshRequirement: () => void
    formData: Record<string, any>
    valid: boolean
  }

  let {
    accountRequirements,
    onRefreshRequirement,
    formData = $bindable(),
    valid = $bindable(false),
  }: Props = $props()

  let selectedRequirement = $state<Requirement | undefined>(
    accountRequirements?.length
      ? accountRequirements.find(r => r.type === formData?.type)
      : undefined,
  )
  let errors = $derived.by(() => {
    if (!selectedRequirement) {
      return {}
    }

    const err: Record<string, string> = {}

    selectedRequirement.fields.forEach(field => {
      field.group.forEach(group => {
        // Prevent clean state showing error
        if (formData.details[group.key] === undefined) {
          return
        }

        if (group.required && formData.details[group.key] === "") {
          err[group.key] = "This field is required"
        }

        if (
          group.validationRegexp &&
          new RegExp(group.validationRegexp).test(
            formData.details[group.key],
          ) === false
        ) {
          err[group.key] = "Invalid input"
        }

        if (
          group.minLength &&
          formData.details[group.key]?.length < group.minLength
        ) {
          err[group.key] = `Minimum ${group.minLength} characters`
        }

        if (
          group.maxLength &&
          formData.details[group.key]?.length > group.maxLength
        ) {
          err[group.key] = `Maximum ${group.maxLength} characters`
        }
      })
    })

    return err
  })

  $effect(() => {
    valid =
      selectedRequirement !== undefined && Object.keys(errors).length === 0
  })

  onMount(() => {
    if (selectedRequirement) {
      onRefreshRequirement()
    }
  })

  function changeRequirementGroup(idx: number) {
    selectedRequirement = accountRequirements[idx]

    formData = {
      type: selectedRequirement.type,
      details: {},
    }
  }

  function handleValueChange(group: Group, value: string) {
    formData = {
      type: selectedRequirement!.type,
      details: merge(formData.details, stringToNestedObject(group.key, value)),
    }

    if (group.refreshRequirementsOnChange) {
      onRefreshRequirement()
    }
  }

  // Handle group name if it has dots, convert to nested object e.g. country.name => { country: { name: "..." } }
  function stringToNestedObject(path: string, value: any): Record<string, any> {
    const keys = path.split(".")
    const result: Record<string, any> = {}
    let current = result

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (i === keys.length - 1) {
        current[key] = value
      } else {
        current[key] = {}
        current = current[key]
      }
    }

    return result
  }

  function accessNestedObject(
    obj: Record<string, any>,
    path: string,
  ): string | Record<string, any> | null {
    const keys = path.split(".")

    return keys.reduce((acc, key) => {
      if (acc && acc[key]) {
        return acc[key]
      }

      return null
    }, obj)
  }

  interface Requirement {
    title: string
    type: string
    fields: Array<Field>
  }

  interface Field {
    name: string
    group: Array<Group>
  }

  interface Group {
    key: string
    name: string
    type: "text" | "select" | "radio" | "date"
    refreshRequirementsOnChange: boolean
    required: boolean
    displayFormat: string
    example: string
    minLength: number
    maxLength: number
    validationRegexp: string
    validationAsync: string
    valuesAllowed: Array<ValueAllowed>
  }

  interface ValueAllowed {
    key: string
    name: string
  }
</script>

<Tabs tabStyle="underline" contentClass="bg-transparent">
  {#each accountRequirements as requirement, idx}
    {#if requirement.type !== "email"}
      <TabItem
        title={requirement.title}
        onclick={() => changeRequirementGroup(idx)}
        open={requirement.type === selectedRequirement?.type}
      >
        <div class="flex flex-col gap-2">
          {#each requirement.fields as field}
            <!-- Render field groups -->
            <div class="flex flex-col gap-2">
              {#each field.group as group}
                <label
                  for={group.key}
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {group.name}
                </label>

                <!-- Untested since there I don't find any requirements returning date type -->
                {#if group.type === "date"}
                  <Datepicker
                    required={group.required}
                    on:select={e =>
                      handleValueChange(
                        group,
                        new Date(e.detail).toISOString(),
                      )}
                    value={accessNestedObject(
                      formData.details,
                      group.key,
                    ) as Date}
                  />
                {:else if group.type === "select"}
                  <Select
                    id={group.key}
                    required={group.required}
                    class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    items={group.valuesAllowed?.map(v => ({
                      value: v.key,
                      name: v.name,
                    }))}
                    onchange={e =>
                      handleValueChange(group, e.currentTarget.value)}
                    value={accessNestedObject(formData.details, group.key)}
                  ></Select>
                {:else if group.type === "radio"}
                  {#each group.valuesAllowed as value}
                    <Radio
                      value={value.key}
                      name={group.name}
                      class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onchange={e =>
                        handleValueChange(group, e.currentTarget.value)}
                      required={group.required}
                      checked={accessNestedObject(
                        formData.details,
                        group.key,
                      ) === value.key}
                    >
                      {value.name}
                    </Radio>
                  {/each}
                {:else}
                  <input
                    id={group.key}
                    type={group.type}
                    required={group.required}
                    min={group.minLength}
                    max={group.maxLength}
                    class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={group.example}
                    onchange={e =>
                      handleValueChange(group, e.currentTarget.value)}
                    value={accessNestedObject(formData.details, group.key)}
                  />
                {/if}

                {#if errors[group.key]}
                  <Helper class="mt-2" color="red">{errors[group.key]}</Helper>
                {/if}
              {/each}
            </div>
          {/each}
        </div>
      </TabItem>
    {/if}
  {/each}
</Tabs>
