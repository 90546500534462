<script>
  import { useI18n } from "$lib/i18n"

  const I18n = useI18n()

  let { children } = $props()
</script>

<div class="bg-white border border-stone-300 rounded-xl my-8 py-16">
  <div class="mx-auto max-w-7xl mb-8 sm:px-2 lg:px-8">
    <div class="mx-auto max-w-7xl px-4 lg:max-w-4xl lg:px-0">
      <h1 class="text-2xl font-bold text-stone-900 sm:text-3xl">
        {I18n.t("views.profile.bank_account.form.title")}
      </h1>
      <p class="mt-2 text-sm text-stone-500">
        {I18n.t("views.profile.bank_account.form.desc")}
      </p>
    </div>
  </div>

  <h2 class="sr-only">
    {I18n.t("views.profile.bank_account.form.title")}
  </h2>
  <div class="mx-auto max-w-7xl sm:px-2 lg:px-8 lg:mb-16">
    <div class="mx-auto max-w-7xl px-4 lg:max-w-4xl lg:px-0">
      <div class="flex flex-col gap-8">
        {@render children()}
      </div>
    </div>
  </div>
</div>
