<script lang="ts">
  import { useI18n } from "$lib/i18n"

  import Layout from "./Layout.svelte"
  import InternationalBankAccountForm from "./InternationalBankAccountForm.svelte"
  import ThaiBankAccountForm from "./ThaiBankAccountForm.svelte"
  import SupportThaiBankModal from "./SupportThaiBankModal.svelte"

  const I18n = useI18n()
  const t = I18n.t.bind(I18n)

  let { bankAccount, currencies, thaiBanks, formUrl, formUrlJson } = $props()

  let mode = $state(
    bankAccount ? (!bankAccount.account_type ? "thai" : "international") : null,
  )
  let selection = $state(
    bankAccount ? (!bankAccount.account_type ? "thai" : "international") : null,
  )
  let showModal = $state(false)

  function toggleModal() {
    showModal = true
  }
</script>

{#snippet bankAccountBreadcrumb(currentAccountType: string)}
  <nav class="flex" aria-label="Breadcrumb">
    <ol
      class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse"
    >
      <li class="inline-flex items-center">
        <button
          onclick={() => (mode = null)}
          class="inline-flex items-center text-sm font-medium text-stone-700 hover:text-blue-600 dark:text-stone-400 dark:hover:text-white"
        >
          <svg
            class="w-3 h-3 text-stone-400 mx-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span
            class="ms-1 text-sm font-medium text-stone-500 md:ms-2 dark:text-stone-400"
            >{t("views.bank_account.edit.select_account_type")}</span
          >
        </button>
      </li>
      <li class="inline-flex items-center">
        <button
          class="inline-flex items-center text-sm font-medium text-stone-700 hover:text-blue-600 dark:text-stone-400 dark:hover:text-white"
          disabled={true}
        >
          <svg
            class="w-3 h-3 text-stone-400 mx-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span
            class="ms-1 text-sm font-medium text-stone-500 md:ms-2 dark:text-stone-400"
            >{currentAccountType}</span
          >
        </button>
      </li>
    </ol>
  </nav>
{/snippet}

<Layout>
  {#if !mode}
    <div class="mx-auto max-w-2xl w-full bg-white rounded-lg p-8 shadow-sm">
      <!-- Header -->
      <h1 class="text-3xl font-bold text-indigo-900 mb-2">
        Please select the type of<br />
        account you want to verify.
      </h1>
      <p class="text-gray-600 mb-8">
        Are you using a Thai bank account or an International bank account?
      </p>

      <!-- Selection Cards -->
      <div class="grid md:grid-cols-2 gap-4 mb-12">
        <!-- Thai Bank Card -->
        <button
          class="text-start border-2 border-gray-200 rounded-xl p-6 bg-white cursor-pointer hover:bg-gray-50 transition inline-flex flex-col items-start"
          onclick={() => (selection = "thai")}
          class:border-indigo-900={selection === "thai"}
        >
          <div class="flex items-center mb-4">
            <div
              class="w-8 h-8 bg-gray-400 rounded-full flex items-center justify-center text-white font-bold"
              class:bg-indigo-900={selection === "thai"}
            >
              ฿
            </div>
          </div>
          <h2 class="text-xl font-bold text-gray-900 mb-2">
            Thai Bank Account
          </h2>
          <p class="text-sm text-gray-600 mb-4">
            Thai Bank Account: Use a bank account issued in Thailand
          </p>
          <p class="text-sm text-gray-700 font-medium mb-1">Such as</p>
          <ul class="text-sm text-gray-600 space-y-1">
            <li>• Bangkok Bank</li>
            <li>• Kasikorn Bank</li>
            <li>• Siam Commercial Bank</li>
            <li>
              <span
                role="button"
                tabindex="0"
                class="text-indigo-900 font-bold hover:underline"
                onclick={toggleModal}
                onkeydown={toggleModal}
              >
                • And more
              </span>
            </li>
          </ul>
        </button>

        <!-- International Bank Card -->
        <button
          class="text-start border-2 border-gray-200 rounded-xl p-6 hover:bg-gray-50 cursor-pointer transition inline-flex flex-col items-start"
          onclick={() => (selection = "international")}
          class:border-indigo-900={selection === "international"}
        >
          <div class="flex items-center mb-4">
            <div
              class="w-8 h-8 bg-gray-400 rounded-full flex items-center justify-center text-white font-bold"
              class:bg-indigo-900={selection === "international"}
            >
              $
            </div>
          </div>
          <h2 class="text-xl font-bold text-gray-900 mb-2">
            International Bank Account
          </h2>
          <p class="text-sm text-gray-600 mb-4">
            International Bank Account: Use an account outside of Thailand
          </p>
          <!-- <p class="text-sm text-gray-700 font-medium mb-1">Such as</p>
          <ul class="text-sm text-gray-600 space-y-1">
            <li>• HSBC</li>
            <li>• Citibank</li>
            <li>• Banks in other countries</li>
          </ul> -->
        </button>
      </div>

      <!-- Action Buttons -->
      <div class="flex justify-end space-x-4">
        <button
          class="px-6 py-2 border border-gray-300 rounded-full text-gray-700 hover:bg-gray-50 transition"
          onclick={() => window.history.back()}
        >
          Cancel
        </button>
        <button
          class="px-6 py-2 bg-indigo-900 text-white rounded-full hover:bg-indigo-900 transition"
          onclick={() => (mode = selection)}
        >
          Next
        </button>
      </div>
    </div>
  {:else if mode === "thai"}
    {@render bankAccountBreadcrumb(t("views.bank_account.edit.thai_account"))}

    <ThaiBankAccountForm {bankAccount} banks={thaiBanks} {formUrl} />
  {:else}
    {@render bankAccountBreadcrumb(
      t("views.bank_account.edit.international_account"),
    )}

    <InternationalBankAccountForm
      {bankAccount}
      {currencies}
      formUrl={formUrlJson}
    />
  {/if}

  <SupportThaiBankModal bind:showModal />
</Layout>
