<script lang="ts">
  import { useI18n } from "$lib/i18n"

  const I18n = useI18n()
  const t = I18n.t.bind(I18n)

  let { bankAccount, banks, formUrl } = $props()
</script>

{#if !bankAccount?.verified}
  <div
    class="w-full flex p-4 gap-2 text-sm text-yellow-800 bg-white border border-yellow-300 rounded-lg dark:bg-gray-800 dark:text-stone-400 dark:border-yellow-800"
    role="alert"
  >
    <i data-lucide="info" class="flex-shrink-0 inline w-4 h-4 mt-0.5"></i>
    <span class="sr-only">Notice Info</span>

    <div class="flex flex-col gap-2">
      <span class="text-yellow-600"
        >&ast; {I18n.t("helpers.form.verification_text")}</span
      >
      <!-- <span>
        &ast;&ast;
        {I18n.t("views.shared.bankbook_warning.line_1")}

        <span
          data-modal-target="payout-bank-support-modal"
          data-modal-toggle="payout-bank-support-modal"
          class="font-bold text-yellow-600 hover:underline hover:cursor-pointer"
        >
          {I18n.t("views.shared.bankbook_warning.line_2")}
        </span>

        {I18n.t("views.shared.bankbook_warning.line_3")}
      </span> -->
    </div>
  </div>
{/if}

<form
  action={formUrl}
  class="flex flex-col gap-4"
  method={"POST"}
  enctype="multipart/form-data"
>
  <input type="hidden" name="_method" value={bankAccount ? "PUT" : "POST"} />
  <input
    type="hidden"
    name="bank_account[wise_recipient_currency]"
    value={null}
  />

  <div class="flex flex-col gap-2">
    <label for="name" class="required">{t("helpers.form.name")}</label>
    <input
      id="name"
      type="text"
      required
      name="bank_account[name]"
      value={bankAccount?.name}
    />
  </div>

  <div class="flex flex-col gap-2">
    <label for="tax_id" class="required">{t("helpers.form.tax_id")}</label>
    <input
      id="tax_id"
      type="text"
      required
      name="bank_account[tax_id]"
      value={bankAccount?.tax_id}
    />
  </div>

  <div class="flex flex-col gap-2">
    <label for="brand" class="required">{t("helpers.form.brand")}</label>
    <select
      id="brand"
      required
      name="bank_account[brand]"
      value={bankAccount?.brand}
    >
      <option value="">{t("helpers.form.choose_bank")}</option>
      {#each banks as [key, name]}
        <option value={key}>{name}</option>
      {/each}
    </select>
  </div>

  <div class="flex flex-col gap-2">
    <label for="account_name" class="required"
      >{t("helpers.form.account_name")}</label
    >
    <input
      id="account_name"
      type="text"
      required
      name="bank_account[account_name]"
      value={bankAccount?.account_name}
    />
  </div>

  <div class="flex flex-col gap-2">
    <label for="account_number" class="required"
      >{t("helpers.form.account_number")}</label
    >
    <input
      id="account_number"
      type="text"
      required
      name="bank_account[account_number]"
      value={bankAccount?.account_number}
    />
  </div>

  <div class="flex flex-col gap-2">
    <label for="bank_book_image" class="required"
      >{t("helpers.form.bank_book_image")}</label
    >
    <input
      id="bank_book_image"
      type="file"
      required
      name="bank_account[bank_book_image]"
    />
  </div>

  <hr class="w-full h-px bg-stone-300 border-0 dark:bg-stone-700" />

  <div class="flex flex-wrap-reverse sm:flex-nowrap sm:justify-between gap-4">
    <a class="btn w-full sm:w-auto" href="/profile/account">
      {t("routes.back")}
    </a>

    <button class="btn btn--brand w-full sm:w-auto"
      >{t("helpers.form.confirm_btn")}</button
    >
  </div>
</form>
